import { gql } from '@apollo/client';

export const GET_USER_BY_AUTH = gql`
query {
    GetUserByAuth {
        _id
        email
        firstName
        lastName
        phone
        website
        firebaseUid
        updatedAt
        createdAt
        role {
          _id
          title
        }
        image {
          _id
          imageFullUrl
          imageThumbUrl
        }
        account {
          _id
          updatedAt
          holder {
              _id
              updatedAt
              email
              firstName
              lastName
          }
          users {
            updatedAt
            _id
          }
        }
    }
  }
`;

export const UPDATE_USER = gql`
mutation UpdateUser($firstName: String, $lastName: String, $website: String, $phone: String, $imageId: String) {
  UpdateUser(userUpdateInput:{
    firstName: $firstName
    lastName: $lastName
    website: $website
    phone: $phone
    imageId: $imageId
    verified: true
  }) {
    _id
    email
    firstName
    lastName
    phone
    website
    firebaseUid
    updatedAt
    createdAt
    role {
      _id
      title
    }
    image {
      _id
      imageFullUrl
      imageThumbUrl
    }
    account {
      _id
      holder {
          _id
          email
          firstName
          lastName
      }
      users {
          _id
      }
    }
  }
}
`;

export const CREATE_USER_BY_AUTH = gql`
mutation CreateUserByAuth($firstName: String!, $lastName: String!, $email: String!) {
  CreateUserByAuth(userCreateInput:{
    firstName: $firstName
    lastName: $lastName
    email: $email
  }) {
    _id
    email
    lastName
    updatedAt
    createdAt
    role {
        _id
        title
    }
  }
}
`;

export const CREATE_USER_BY_FIREBASE = gql`
mutation CreateUserByFireBase($firebaseUid: String!, $firstName: String!, $lastName: String!, $email: String!) {
  CreateUserByFireBase(userFirebaseCreateInput:{
    firebaseUid: $firebaseUid
    firstName: $firstName
    lastName: $lastName
    email: $email
  }) {
    _id
    email
    lastName
    updatedAt
    createdAt
    role {
        _id
        title
    }
  }
}
`;

export const UPDATE_USER_BY_EXIST_ACCOUNT = gql`
mutation UpdateUserByExistAccount($firebaseUid: String!, $email: String!) {
  UpdateUserByExistAccount(userUpdateByExistAccountInput:{
    firebaseUid: $firebaseUid
    email: $email
  }) {
    _id
    email
    updatedAt
    createdAt
    role {
        _id
        title
    }
  }
}
`;

