import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module'
//Import Env
import { DEBUG } from './env'

if (DEBUG() === false) {
    const tagManagerArgs = {
        gtmId: ''
    }

    TagManager.initialize(tagManagerArgs);
}

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(app);