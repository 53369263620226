import cookie from 'js-cookie';

//Import Env
import { DEBUG } from '../env'

const FIREBASE_COOKIE = '__session';
const FIREBASE_USER_KEY  = '__user'
const FIREBASE_REMEMBER_KEY  = '__remember'

const getCookieFromBrowser = key => {
  return cookie.get(key);
};

export let getCookie = (key) => {
  return getCookieFromBrowser(key);
};

export let setCookie = (key, token) => {
  cookie.set(key, token, { expires: 7 });
};

export let removeCookie = key => {
  cookie.remove(key);
};

let setFirebaseCookie = (key, token) => {
  // get cookie from __session using getCookie function
  // parse the data from cookie
  // assign the token to the key
  // call set cookie function with the new data
  try {
    const cookieData = getCookie(FIREBASE_COOKIE);
    const data = cookieData ? JSON.parse(cookieData) : {};
    data[key] = token;
    setCookie(FIREBASE_COOKIE, data);
  } catch (error) {
    if (DEBUG() === true) console.log(error, 'setFirebaseCookie');
  }
};

let removeFirebaseCookie = (key) => {
  // get cookie from __session  using getCookie function
  // parse the data from cookie
  // remove the key from the data
  // call set cookie function with the new data
  try {
    const cookieData = getCookie(FIREBASE_COOKIE);
    const data = cookieData ? JSON.parse(cookieData) : {};
    if (data && data.hasOwnProperty(key)) {
      delete data[key];
      setCookie(FIREBASE_COOKIE, data);
    } else {
      // console.log(
      //   `${key} is probably not available inside the cookie removeFirebaseCookie`
      // );
    }
  } catch (error) {
    if (DEBUG() === true) console.log(error, 'removeFirebaseCookie');
  }
};

export let getFirebaseCookie = (key) => {
  // get cookie from __session using getCookie function
  // parse the data from cookie
  // get the relatedData using the key
  try {
    const cookieData = getCookie(FIREBASE_COOKIE);
    const data = cookieData ? JSON.parse(cookieData) : {};
    if (data && data.hasOwnProperty(key)) {
      return data[key];
    } else {
      // console.log(
      //   `${key} is probably not available inside the cookie getFirebaseCookie`
      // );
    }
  } catch (error) {
    if (DEBUG() === true) console.log(error, 'getFirebaseCookie error');
  }
};

export let getFirebaseUser = () => {
  const user = getFirebaseCookie(FIREBASE_USER_KEY);
  if (user) {
    const userJson = JSON.stringify(user);
    const data = JSON.parse(userJson);
    //if (DEBUG() === true) console.log('getFirebaseUser userData' + data);
    //if (DEBUG() === true) console.log('getFirebaseUser userData uid:' + data.uid + 'emailVerified:' + data.emailVerified);
    return data;
  }
  return null;
}

export let setFirebaseUser = (userData) => {
  const json = JSON.stringify(userData);
  if (DEBUG() === true) console.log("context user json:" + json);
  const data = JSON.parse(json);
  const userId = data.uid;
  if (DEBUG() === true) console.log("context userId:" + userId);

  setFirebaseCookie(FIREBASE_USER_KEY, { ...data });

}

export let removeFirebaseUser = () => {
  removeFirebaseCookie(FIREBASE_USER_KEY);
  removeFirebaseCookie(FIREBASE_REMEMBER_KEY);
};

export let setRememberUser = (isRememberMe) => {
  setFirebaseCookie(FIREBASE_REMEMBER_KEY, { 
    rememberMe: isRememberMe,
    updateAt: new Date().toISOString(),
  });
}

export let getRememberUser = () => {
  return getFirebaseCookie(FIREBASE_REMEMBER_KEY);
}
