export let InitStateData = () => {
    return {
        isLoading:false,
        isLogin: false,
        isRememberMe: false,
        userData: {
            email: null,
            role: {
              title: null
            },
            image: {
              _id: null,
              imageFullUrl: null
            },
            account: {
              _id: null,
              phone: null,
              holder: {
                email: null,
              },
              users: null
            }
          }
    };
  }