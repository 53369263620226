/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {useHistory} from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
// react plugin used to create charts
//import {Line} from "react-chartjs-2";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Col, UncontrolledCarousel
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

const carouselItems = [
    {
        src: require("../../assets/img/game-img/Spartans3_3_5.png"),
        altText: "Slide 1",
        caption: ""
    },
    {
        src: require("../../assets/img/game-img/Trio3_2.png"),
        altText: "Slide 2",
        caption: ""
    },
    {
        src: require("../../assets/img/game-img/SpartanAthenianShot1.png"),
        altText: "Slide 3",
        caption: ""
    }
];

function JoinButton() {
    const history = useHistory();

    function handleClick() {
        history.push("/register-page");
    }

    return (
        <Button
            className="btn-link"
            color="success"
            onClick={handleClick}
            size="sm"
        >
            <p className="category text-success d-inline">
                Join Now
            </p>
            <i className="tim-icons icon-minimal-right"/>
        </Button>
    );
}

export default function LandingPage() {
    React.useEffect(() => {
        document.body.classList.toggle("landing-page");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("landing-page");
        };
    }, []);

    return (
        <>
            <ExamplesNavbar/>
            <div className="wrapper">
                <div className="page-header">
                    <img
                        alt="..."
                        className="path"
                        src={require("../../assets/img/blob.png")}
                    />
                    <img
                        alt="..."
                        className="path2"
                        src={require("../../assets/img/path2.png")}
                    />
                    <img
                        alt="..."
                        className="shapes triangle"
                        src={require("../../assets/img/triunghiuri.png")}
                    />
                    <img
                        alt="..."
                        className="shapes wave"
                        src={require("../../assets/img/waves.png")}
                    />
                    <img
                        alt="..."
                        className="shapes squares"
                        src={require("../../assets/img/patrat.png")}
                    />
                    <img
                        alt="..."
                        className="shapes circle"
                        src={require("../../assets/img/cercuri.png")}
                    />
                    <div className="content-center">
                        <Row className="row-grid justify-content-between align-items-center text-left">
                            <Col lg="6" md="6">
                                <h1 className="text-white">
                                    Experience the intensity <br/>
                                    <span className="text-white">of ancient combat</span>
                                </h1>
                                <p className="text-white mb-3">
                                    Introducing 'The Era Project', the first game from our
                                    indie development team.
                                    <br/><br/>
                                    As new developers, we're excited to bring you a multiplayer experience like no
                                    other. In 'The Era Project', you'll command a small unit of 20 companions and engage
                                    in intense and strategic battles against other players in thrilling game modes like
                                    Free-for-all and Team Death Match. With its 3rd person perspective and action-packed
                                    combat, 'The Era Project' is a unique blend of slasher and RTS gameplay.
                                    <br/><br/>
                                    Choose from a variety of factions from the ancient world, each with their own
                                    strengths and abilities, and fight for victory on beautifully crafted battlefields
                                    that are true to the time period.
                                    <br/><br/>
                                    Join us on our journey as we embark on our first game development project together.
                                    Sign up now to be notified when 'The Era Project' is
                                    released and experience the thrill of ancient combat like never before.
                                </p>
                                <div className="btn-wrapper mb-3">
                                    <JoinButton/>
                                </div>
                                {/* <div className="btn-wrapper">
                                    <div className="button-container">
                                        <Button
                                            className="btn-icon btn-simple btn-round btn-neutral"
                                            color="default"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="fab fa-twitter"/>
                                        </Button>
                                        <Button
                                            className="btn-icon btn-simple btn-round btn-neutral"
                                            color="default"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="fab fa-dribbble"/>
                                        </Button>
                                        <Button
                                            className="btn-icon btn-simple btn-round btn-neutral"
                                            color="default"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="fab fa-facebook"/>
                                        </Button>
                                    </div>
                                </div> */}
                            </Col>
                            <Col lg="6" md="6">
                                {/*<img
                                    alt="..."
                                    className="img-fluid"
                                    src={require("../../assets/img/game-img/SpartanAthenianShot1.png")}
                                />*/}
                                <Row className="row-grid justify-content-center" style={{padding: 0}}>
                                    <div style={{height: 320, width: "90%"}}>
                                        <ReactPlayer
                                         width="100%"
                                         height="100%"
                                         url='https://www.youtube.com/watch?v=fOjcZP_S1jo'/>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/*
                This needs to have a fixed height, so that it doesn't shift everything else below it, need to override the
                height of the carousel component with the style tag.
                */}
                <section className="section section-md">
                    <Container>
                        <Row className="row-grid justify-content-center" style={{padding: 0}}>
                            <Col lg="12" md="12" style={{height: 600}}>
                                <UncontrolledCarousel
                                    items={carouselItems}
                                    indicators={false}
                                    autoPlay={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/*
                <section className="section section-lg" style={{paddingBottom: 0}}>
                    <section className="section">
                        <img
                            alt="..."
                            className="path"
                            src={require("../../assets/img/path4.png")}
                        />
                        <Container>
                            <Row className="row-grid justify-content-between">
                                <Col className="mt-lg-5" md="5">
                                    <Row>
                                        <div className="pl-md-5">
                                            <ReactPlayer url='https://www.youtube.com/watch?v=YiRuiwFvItE'/>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="pl-md-5 mt-5">
                                            <ReactPlayer url='https://www.youtube.com/watch?v=YiRuiwFvItE'/>
                                        </div>
                                    </Row>
                                </Col>
                                <Col md="5">
                                    <div className="pl-md-5">
                                        <h1>
                                            Earn NFT <br/>
                                            Achivements & Awards
                                        </h1>
                                        <p>
                                            In 'The Era Project', you'll have the chance to earn
                                            NFT awards, achievements, and unlockables. These unique, non-fungible tokens
                                            offer a host of benefits to enhance your gaming experience:
                                        </p>
                                         Create list noting the benefits of gaming nfts
                                        <ul className="list-unstyled mt-5">
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <h6 className="ml-0">Rarity and exclusivity:</h6>
                                                        <p>
                                                            NFTs are one-of-a-kind and can't be replicated, making them
                                                            highly coveted prizes for dedicated players.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <h6 className="">Collectibility:</h6>
                                                        <p>
                                                            NFTs can be collected and traded like other valuable items,
                                                            adding an extra layer of fun to your gameplay.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <h6 className="">Status:</h6>
                                                        <p>
                                                            Owning rare or highly coveted NFTs can increase your status
                                                            within the game community and make you more attractive to
                                                            other players.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <h6 className="">Personalization: </h6>
                                                        <p>
                                                            NFTs can be used to customize your avatar or in-game
                                                            experience, adding an extra level of personalization to your
                                                            gameplay.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <h6 className="">Bragging rights:</h6>
                                                        <p>
                                                            Earning NFTs is a source of pride and gives you something to
                                                            brag about to your friends and other players.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <p>
                                            When we launch, you'll be able to earn NFTs by completing quests, winning
                                            battles, completing various score streaks and more. There will be special
                                            edition NFTs for early adopters,
                                            such as usable in game gear and characters, and you'll be able to trade or
                                            sell your NFTs on the secondary market.
                                        </p>
                                        <br/>
                                        <a
                                            className="font-weight-bold text-info mt-5"
                                            href="#"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Show all{" "}
                                            <i className="tim-icons icon-minimal-right text-info"/>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </section>
                */}
                <section className="section section-lg" style={{paddingTop: 0, marginTop: 0}}>
                    <img
                        alt="..."
                        className="path2"
                        src={require("../../assets/img/game-img/Carthagenianpose5_4.png")}
                    />
                    {/*
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <h1 className="text-center">Your best benefit</h1>
                                <Row className="row-grid justify-content-center">
                                    <Col lg="3">
                                        <div className="info">
                                            <div className="icon icon-primary">
                                                <i className="tim-icons icon-money-coins" />
                                            </div>
                                            <h4 className="info-title">Low Commission</h4>
                                            <hr className="line-primary" />
                                            <p>
                                                Divide details about your work into parts. Write a few
                                                lines about each one. A paragraph describing a feature
                                                will.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="info">
                                            <div className="icon icon-warning">
                                                <i className="tim-icons icon-chart-pie-36" />
                                            </div>
                                            <h4 className="info-title">High Incomes</h4>
                                            <hr className="line-warning" />
                                            <p>
                                                Divide details about your product or agency work into
                                                parts. Write a few lines about each one. A paragraph
                                                describing feature will be a feature.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="info">
                                            <div className="icon icon-success">
                                                <i className="tim-icons icon-single-02" />
                                            </div>
                                            <h4 className="info-title">Verified People</h4>
                                            <hr className="line-success" />
                                            <p>
                                                Divide details about your product or agency work into
                                                parts. Write a few lines about each one. A paragraph
                                                describing be enough.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    */}
                </section>
                {/*
                <section className="section section-lg section-safe">
                    <img
                        alt="..."
                        className="path"
                        src={require("../../assets/img/game-img/Carthagenianpose5_4.png")}
                    />
                    <Container>
                        <Row className="row-grid justify-content-between">
                            <Col md="5">
                                <img
                                    alt="..."
                                    className="img-fluid floating"
                                    src={require("../../assets/img/chester-wade.jpg")}
                                />
                                <Card className="card-stats bg-danger">
                                    <CardBody>
                                        <div className="justify-content-center">
                                            <div className="numbers">
                                                <CardTitle tag="p">100%</CardTitle>
                                                <p className="card-category text-white">Safe</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="card-stats bg-info">
                                    <CardBody>
                                        <div className="justify-content-center">
                                            <div className="numbers">
                                                <CardTitle tag="p">573 K</CardTitle>
                                                <p className="card-category text-white">
                                                    Satisfied customers
                                                </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="card-stats bg-default">
                                    <CardBody>
                                        <div className="justify-content-center">
                                            <div className="numbers">
                                                <CardTitle tag="p">10 425</CardTitle>
                                                <p className="card-category text-white">Business</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <div className="px-md-5">
                                    <hr className="line-success" />
                                    <h3>Awesome features</h3>
                                    <p>
                                        The design system comes with three pre-built pages to help
                                        you get started faster. You can change the text and images
                                        and you're good to go.
                                    </p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-success mb-2">
                                                    <i className="tim-icons icon-vector" />
                                                </div>
                                                <div className="ml-3">
                                                    <h6>Carefully crafted components</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-success mb-2">
                                                    <i className="tim-icons icon-tap-02" />
                                                </div>
                                                <div className="ml-3">
                                                    <h6>Amazing page examples</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-success mb-2">
                                                    <i className="tim-icons icon-single-02" />
                                                </div>
                                                <div className="ml-3">
                                                    <h6>Super friendly support team</h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                */}
                {/*
                <section className="section section-lg">

                    <img
                        alt="..."
                        className="path"
                        src={require("../../assets/img/game-img/Spartans3_3_5.png")}
                    />
                    <img
                        alt="..."
                        className="path2"
                        src={require("../../assets/img/game-img/Spartans3_3_5.png")}
                    />

                    <Col md="12">
                        <Card className="card-chart card-plain">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <hr className="line-info" />
                                        <h5 className="card-category">Total Investments</h5>
                                        <CardTitle tag="h2">Performance</CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </section>
                */}
                <section className="section section-lg section-coins" style={{paddingTop: 0, marginTop: 0}}>
                    <img
                        alt="..."
                        className="path"
                        src={require("../../assets/img/path3.png")}
                    />
                    <Container>
                        <Row>
                            <Col md="5">
                                <hr className="line-info"/>
                                <h1>
                                    Meet the talented{" "}
                                    <span className="text-info">Team behind the Game</span>
                                </h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/member-img/sam.jpg")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Sam Hefer</h4>
                                                <span>Developer, Story Teller, Gamer</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center" style={{padding: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Sam has a keen interest in classical history, mythology,
                                                        development and
                                                        gaming.
                                                        He brings his passion for these subjects to the game, and is
                                                        responsible
                                                        for the development, story and lore of the game. Previously, Sam
                                                        has worked
                                                        as a Software Developer, Analyst and Engineer for a number of
                                                        companies.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/member-img/ying.jpg")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Ying Lu</h4>
                                                <span>Developer, Gamer</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center"
                                                 style={{paddingLeft: 10, paddingRight: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Ying's interests are in playing and making games, and writing
                                                        great software.
                                                        He brings his passion and his 10+ years of experience in
                                                        development to the game, and is responsible
                                                        for the development of the game and the website. Previously,
                                                        He has worked
                                                        as a Software Developer, Mobile App Developer, Unity game
                                                        developer and Full stack web development.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/member-img/eetu.png")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Eetu Niemela</h4>
                                                <span>Artist, Designer, Story Teller</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center" style={{padding: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Eetu brings his passion for art, ancient warfare, history and
                                                        cinematic visuals to the game. He is responsible for the art and
                                                        visual design of the game, as well as cinematography and story.
                                                        Eetu brings 2 and a half years of working on his own projects
                                                        doing design, creating worlds and telling stories.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row style={{paddingTop: 100}}>
                            <Col md="4">
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/member-img/spencer.jpg")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Spencer</h4>
                                                <span>Developer, Gamer, Teacher</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center" style={{padding: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Interests include developing games, playing action, FPS, and
                                                        strategy games, and learning about new/old technology. I bring
                                                        my curiosity, adaptable learning, countless hours of research to
                                                        the game. I've been a freelance developer and programmer, I've
                                                        helped others on game dev discord servers, and I've learned a
                                                        lot of various tips and tricks to make developing easier.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/mike.jpg")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Seth</h4>
                                                <span>Developer, Story Teller</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center" style={{padding: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Sam has a keen interest in classical history, mythology, and
                                                        gaming.
                                                        He brings his passion for these subjects to the game, and is
                                                        responsible
                                                        for the development, story and lore of the game. Previously, Sam
                                                        has worked
                                                        as a Software Developer, Analyst and Engineer for a number of
                                                        companies.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/mike.jpg")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Raika Mase</h4>
                                                <span>Developer, Blockchain Specialist</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center" style={{padding: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Raika is a passionate gamer and a lover of all things fantasy.
                                                        He enjoys listening to music and making games.
                                                        He is a developer and blockchain specialist for the game.
                                                        Previously he has spent 8 years working in web and 3 years in
                                                        blockchain development, successfully completing DeFi and Dex
                                                        blockchain platforms.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row> */}
                        {/*
                        <Row style={{paddingTop:100, verticalAlign:"middle"}}>
                            <div style={{verticalAlign:"middle"}}>
                                <Col md="4" >
                                <Card className="card-coin card-plain">
                                    <CardHeader>
                                        <img
                                            alt="..."
                                            className="img-center img-fluid rounded-circle"
                                            src={require("../../assets/img/mike.jpg")}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="text-center" md="12">
                                                <h4 className="text-uppercase">Sam Hefer</h4>
                                                <span>Developer, Story Teller</span>
                                                <hr className="line-primary"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex align-items-center" style={{padding: 10}}>
                                                <div>
                                                    <p className="text-center">
                                                        Sam has a keen interest in classical history, mythology, and gaming.
                                                        He brings his passion for these subjects to the game, and is responsible
                                                        for the development, story and lore of the game. Previously, Sam has worked
                                                        as a Software Developer, Analyst and Engineer for a number of companies.
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button className="btn-simple" color="primary">
                                            See more
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                            </div>
                        </Row>
                        */}
                    </Container>
                </section>
                <Footer/>
            </div>
        </>
    );
}
