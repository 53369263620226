// React Basic and Bootstrap
import React, { Component } from 'react';
//Import Env
import { DEBUG } from '../../env'
//Impot State Data
import { InitStateData } from '../../helpers/stateDataHelper'
//Import Context
import AuthContext from '../../context/auth-context';

import LandingPage from "../examples/LandingPage";

class LandingPageComponent extends Component {

    static contextType = AuthContext;

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // ...
        if (DEBUG() === true) console.log('LandingPageComponent getSnapshotBeforeUpdate');

        return null;
    }

    componentDidUpdate(prevProps) {
        if (DEBUG() === true) console.log('LandingPageComponent componentDidUpdate');

        // if (DEBUG() === true) console.log('MerchantListingsDetail componentDidUpdate:' + prevProps.location.state);
    }

    constructor(props) {
        super(props);

        this.state = {
            ...InitStateData(),
            isLoading: true
        }
    }

    componentDidMount() {
        if (this.context.isLogin && this.context.userData) {
            if (DEBUG() === true) console.log('LandingPageComponent componentDidMount isLogin:' + this.context.isLogin);
            if (DEBUG() === true) console.log('LandingPageComponent componentDidMount isLogin email:' + this.context.userData.email);

            //Check login status 
            if (this.context.userData.email !== null) {
                this.setState({
                    isLoading: false,
                    isLogin: this.context.isLogin,
                    userData: this.context.userData
                });
                if (this.context.userData.account) {
                    //TOOD Check merchant
                }
            }
            
        } else {
            //This Page is for logged in user only, check login status 
            this.setState({
                isLoading: false,
                userData: null
            });
        }
    }

    render() {
        return (
            <LandingPage userData={this.state.userData} firebase={this.context.firebase} />
        );
    }
}
export default LandingPageComponent;