import React, { Component, Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";

// Import Css
import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss";
import "./assets/demo/demo.css";

import Index from "./views/Index.js";
import LandingPageComponent from "./views/components/LandingPageComponent.js";
import RegisterPageComponent from "./views/components/RegisterPageComponent.js";
import ProfilePage from "./views/examples/ProfilePage.js";

//Impot State Data
import { InitStateData } from './helpers/stateDataHelper'
//Import Firebase
import { firebaseWeb } from './firebase/index'

//Import Context
import AuthContext from './context/auth-context';
import { updateAuth, logoutAuth } from './context/AuthManager';

//Import Graphq
import { ApolloProvider } from '@apollo/client';
import graphqlClient from '../src/graphql/index'

//Import Env
import { DEBUG } from './env'

import TagManager from 'react-gtm-module'

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...InitStateData()
        };
    }

    appLogin = async (email, password) => {
        var errMessage = null;
        const userCredential = await firebaseWeb().auth().signInWithEmailAndPassword(email, password).catch(function (error) {
            var errorCode = error.code;
            if (DEBUG() === true) console.log('errorCode' + errorCode);
            errMessage = error.message;
            if (DEBUG() === true) console.log('errMessage:' + errMessage);
        });

        if (userCredential != null) {
            if (DEBUG() === true) console.log('await updateAuth');
            await updateAuth(this, firebaseWeb());
        }

        return {
            userCredential: userCredential,
            errMessage: errMessage,
        };
    }

    appLogout = async () => {
        await logoutAuth(this, firebaseWeb());
        this.redirectHome();
    };

    redirectHome = () => {
        window.location.href = "/";
    }

    componentDidMount() {
        if (DEBUG() === true) console.log('App componentDidMount');
        updateAuth(this, firebaseWeb());
    }

    componentWillUnmount() {

    }

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );
    }
    render() {

        if (DEBUG() === false) {
            const tagManagerArgs = {
                dataLayer: {
                    email: this.state.userData.email,
                    id: this.state.userData._id
                }
            }

            TagManager.dataLayer(tagManagerArgs);
        }

        if (DEBUG() === true) console.log('App render user role title:' + this.state.userData.role.title);
        if (DEBUG() === true) console.log('App render user email:' + this.state.userData.email);
        if (DEBUG() === true) console.log('App render user id:' + this.state.userData._id);


        return (
            <ApolloProvider client={graphqlClient}>
                <React.Fragment>
                    <AuthContext.Provider
                        value={{
                            isLogin: this.state.isLogin,
                            userData: this.state.userData,
                            appLogin: this.appLogin,
                            appLogout: this.appLogout,
                            redirectHome: this.redirectHome,
                            firebase: firebaseWeb()
                        }}
                    >
                        <Switch>
                            <Route path="/components" render={(props) => <Index {...props} />} />
                            <Route
                                path="/landing-page"
                                render={(props) => <LandingPageComponent {...props} />}
                            />
                            <Route
                                path="/register-page"
                                render={(props) => <RegisterPageComponent {...props} />}
                            />
                            <Route
                                path="/profile-page"
                                render={(props) => <ProfilePage {...props} />}
                            />
                            <Redirect from="/" to="/landing-page" />
                        </Switch>
                    </AuthContext.Provider>

                </React.Fragment>
            </ApolloProvider>
        );
    }
}

export default withRouter(App);